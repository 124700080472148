/*
 * @Author: your name
 * @Date: 2021-03-16 09:19:05
 * @LastEditTime: 2021-09-26 17:17:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sun_highschool\src\router\index.js
 */

// const Home = resolve => require.ensure([], () => resolve(require('@/components/res/Home/Home')), 'Home')

// const LectureResources = resolve => require.ensure([], () => resolve(require('@/components/res/LectureResources/Index')), 'res.tbzy')
// const LectureResourcesSchool = resolve => require.ensure([], () => resolve(require('@/components/res/LectureResources/IndexSchool')), 'res.school')
// const SunSpecialResourceDetailIndex = resolve => require.ensure([], () => resolve(require('@/components/res/SunSpecialRes/ResDetailIndex')), 'res.sun.special.detail')
// const SunMyResourceIndex = resolve => require.ensure([], () => resolve(require('@/components/res/SunMyResource/Index')), 'res.sun.mine')
// const ResourcePreview = resolve => require.ensure([], () => resolve(require('@/components/res/ResourcePreview')), 'res.preview')

// const ResIndex = resolve => require.ensure([], () => resolve(require('@/components/res/Index')), 'res.index')
// const LayoutHeaderFooter = resolve => require.ensure([], () => resolve(require('@/components/common/site/LayoutHeaderFooter')), 'layout.hf')
const ResRouter = resolve => require.ensure([], () => resolve(require('@/components/res/ResRouter')), 'res.router')
const NewTbkc = resolve => require.ensure([], () => resolve(require('@/components/res/LectureResources/TbkcIndex')), 'res.NewTbkc')
const SunSpecialResourceIndex = resolve => require.ensure([], () => resolve(require('@/components/res/SunSpecialRes/Index')), 'res.sun.special')
const SunNewDetail = resolve => require.ensure([], () => resolve(require('@/components/res/SunNewComponents/SunNewDetail')), 'res.SunNewDetail')
const NewTree = resolve => require.ensure([], () => resolve(require('@/components/res/SunNewComponents/NewTree/NewTree')), 'res.NewTree')
const Tbcp = resolve => require.ensure([], () => resolve(require('@/components/res/Tbcp/TbcpIndex')), 'res.Tbcp')
const Gkfx = resolve => require.ensure([], () => resolve(require('@/components/res/Gkfx/GkfxIndex')), 'res.Gkfx')
const GoToView = resolve => require.ensure([], () => resolve(require('@/components/home/GoToView')), 'res.GoToView')
const Jpjk = resolve => require.ensure([], () => resolve(require('@/components/res/Jpjk/JpjkIndex')), 'res.Jpjk')
const ErrorPage = resolve => require.ensure([], () => resolve(require('@/components/ErrorPage/ErrorPageIndex')), 'res.ErrorPage')
const Tskc = resolve => require.ensure([], () => resolve(require('@/components/res/Tskc/TskcIndex')), 'res.Tbcp')

const Tabframe = resolve => require.ensure([], () => resolve(require('@/components/wxt/Tabframe')), 'tabframe')
const Agentsetting = resolve => require.ensure([], () => resolve(require('@/components/wxt/Agentsetting')), 'agentsetting')

const Zkfx = resolve => require.ensure([], () => resolve(require('@/components/res/Zkfx/ZkfxIndex')), 'res.Zkfx')

const routers = [
    {
        path: '/',
        component: ResRouter,
        name: '资源中心',
        redirect: '/Res/Tbkc',
        children: [
            {
                path: '/ErrorPage',
                name: '错误页面',
                component: ErrorPage,
            },
            {
                path: '/Res/Tbkc',
                name: '同步课程',
                component: NewTbkc,
                meta: { isNeedNav: true, requiresAuth: true }
            }, 
            {
                path: '/Res/Tbcp',
                name: '同步测评',
                component: Tbcp,
                meta: { isNeedNav: true, requiresAuth: true }
            }, 
            {
                path: '/Res/Jbzy',
                name: '校本资源',
                component: Tbcp,
                meta: { isNeedNav: true, requiresAuth: true }
            },
            {
                path: '/Res/Tbzy',
                name: '同步资源',
                component: NewTbkc,
                meta: { isNeedNav: true, requiresAuth: true }
            },
            {
                path: '/Res/Jpjk',
                name: '精品卷库',
                component: Jpjk,
                meta: { isNeedNav: true, requiresAuth: true }
            }, 
            {
                path: '/Res/Gkfx',
                name: '高考复习',
                component: Gkfx,
                meta: { isNeedNav: true, requiresAuth: true }
            }, 
            {
                path: '/Res/Tszt',
                name: '特色专题',
                component: Tbcp,
                meta: { isNeedNav: true, requiresAuth: true }
            },
            {
                path: '/Res/Ztxx',
                name: '专题学习',
                component: Tbcp,
                meta: { isNeedNav: true, requiresAuth: true }
            },
            {
                path: '/Res/SunNewDetail',
                name: '试卷详情',
                component: SunNewDetail,
                meta: { isNeedNav: true, requiresAuth: true }
            },
            {
                path: '/Res/NewTree',
                name: '试卷详情',
                component: NewTree,
                meta: { isNeedNav: true, requiresAuth: true }
            },
            {
                path: '/Res/Zkfx',
                name: '中考复习',
                component: Zkfx,
                meta: { isNeedNav: true, requiresAuth: true }
            },
        ],
    },
    {
        path: '/Home/GoToView',
        name: '中转',
        component: GoToView,
        children: []
    },
    {
        path: '*',
        redirect: '/Res/Tbkc',
        name: '同步课程',
        component: NewTbkc,
        meta: { isNeedNav: true, requiresAuth: true }
    }

]
export default routers