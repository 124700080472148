<!--
 * @Author: your name
 * @Date: 2021-03-23 18:09:39
 * @LastEditTime: 2021-09-26 10:32:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\common\site\Loading.vue
-->
<template>
<!-- <div v-if='$store.state.res.loading'>
    <div class="block-div"></div>
    <div class="loading-box">
        <img src="../../../assets/images/site/loading-square.gif" alt="">
        
    </div> -->
<!-- </div> -->
<div id="sloading" >
    <div class="block-div"></div>
    <div class="sloading-out">
        <div class="poem-wrap nosel">
            <img src="../../../assets/images/site/loading-bird.gif" alt="" />
        </div>
    </div>
</div>
</template>

<script>
export default {
    data:{
        loadingAjaxList:[]
    },
    watch:{
        '$store.state.res.loading':{
            handler:function(val){
                val?this.debounce(this.startLoading(),500):this.stopLoading()
            },
            immediate:true
        }
    },
    methods:{
        startLoading(){
            let _this = this
            var $loading = $("#sloading");
            $loading.find(".poem-character").remove();
            $loading.find("img").css("left", 100000);
            this.showModalDialog($loading, true, 0);
            $('.block-div').css({ 'z-index': 3000 });

            if(!$loading.hasClass("poem-getted")){
                $loading.addClass("poem-getted");
                var api = this.getMasterDataCenterUrl() + '/api/Rescource/GetResourceQuery?RecourseType=Common&RecourseKey=Poetry'
                this.$http.get(api).then(result=>{
                    if ($loading.is(":visible")) {
                        // ;(function(result){
                            var timeout = setTimeout(function() {
                                clearTimeout(timeout);
                                if ($loading.is(":visible") && $loading.find(".poem-character").length == 0) {
                                    var resultText = result.data.data.resultText;
                                    var content = resultText.content;
                                    var $wrap = $loading.find(".sloading-out .poem-wrap");
                                    $wrap.find(".poem-character").remove()
                                    $wrap.show();
                                    if(content&&content.length > 0){
                                        for(var i=0;i<content.length;i++){
                                            var item = content[i];
                                            var $character = $("<span>", {class: "poem-character"});
                                            $character.text(item);
                                            $wrap.append($character);
                                        }
                                        startAnimate();
                                        function startAnimate(){
                                            $loading.find("img").css("left", 0);
                                            $loading.find("img").stop(true, true);
                                            $loading.find(".poem-character").css({
                                                "visibility": "hidden"
                                            });
                                            // 0.24s一个字
                                            var oneCharacterTime = 240;
                                            $loading.find(".poem-character:eq(0)").css({
                                                "visibility": "inherit"
                                            });
                                            var count = 0;
                                            window.loadingInterval = setInterval(function() {
                                                count++;
                                                $loading.find(".poem-character:not(visible):eq("+ count +")").css({
                                                    "visibility": "inherit"
                                                });
                                            }, oneCharacterTime);
                                        
                                            $loading.find("img").animate({"left": "100%"}, content.length * oneCharacterTime, "linear", function(){
                                                clearInterval(window.loadingInterval);
                                                startAnimate();
                                            });
                                        }
                                    }  
                                    _this.showModalDialog($loading, true, 0);  
                                }
                            }, 300);
                        // })(result);
                    }
                })
            }
        },
        stopLoading(){
            var $loading = $("#sloading");
            $loading.removeClass("poem-getted");
            if ($loading.is(":visible")) {
                this.unblockUI();
                $loading.hide();
            }
            $('.block-div').css({ 'z-index': 2000 });
            
            $loading.find("img").stop();
            clearInterval(window.loadingInterval);

            try{
                if(this.isValidArray(this.loadingAjaxList)){
                    for(var i=0;i<this.loadingAjaxList.length;i++){
                        var item = this.loadingAjaxList[i];
                        item.abort();
                    }
                }
            }catch(error){}
        },
        //显示弹出框
        showModalDialog(target, showBlockUI, overlayOpacity) {
            if (showBlockUI) {
                this.blockUI(overlayOpacity);
            }
            this.adjustModalDlgPosition(target);
            target.show();
        },
        adjustModalDlgPosition(target) {
            var h = target.height();
            var wh = $(window).height();

            var w = target.width();
            var ww = $(window).width();

            var t = 0;
            if (h < wh)
                t = (wh - h) / 2;

            var l = 0;
            if (w < ww)
                l = (ww - w) / 2;
            target.css({ left: l, top: t });

            if(this.isValid(window.messenger)){
                messenger.send('{"name":"getScrollPosY"}')
                messenger.listen(function(msg){
                    try {
                        msg = JSON.parse(msg);
                    } catch (error) {
                        console.log(error);
                        return
                    }
                    
                    var name = msg.name;
                    var value = msg.value;
                    if(name == "getScrollPosY" && value != ""){
                        var iframeScrollTop = value.iframeScrollTop;
                        var pageScrollTop = value.pageScrollTop;
                        var screenHeight = value.screenHeight;
                        var top = pageScrollTop - iframeScrollTop;
                        top < 0 ? top = 0 : "";
                        top += parseInt(screenHeight / 2);
                        target.css({
                            top: top + 'px',
                            left: "50%",
                            transform: 'translate(-50%, -50%)',
                        });
                    }
                })
            }
        },
        unblockUI() {
            $(".block-div").hide();
        },
        blockUI(opacity) {
            opacity = this.isValid(opacity) ? opacity : 0;
            var $block = $(".block-div").css({ 
                'background-color': 'rgba(0, 0, 0,' + opacity + ')' 
            });
            $block.show();
        }
    }
}
</script>

<style scoped>
    .block-div
    {
        width:100%;
        height:100%;
        cursor:wait;
        background-color:rgba(0,0,0,0);
        position:fixed;
        top:0;
        left:0;
        /*- blockUI的z-index统一设置为2000 -*/
        z-index:2000;
    }
    .loading-box{
        width: 130px;
        height: 70px;
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -35px 0 0 -75px;
        z-index: 3000;
        /*
        border-radius: 50%;
        box-shadow: -10px 0 10px 0px rgba(0, 0, 0, 0.15), 
        0 -10px 10px 0px rgba(0, 0, 0, 0.15), 
        0 10px 10px 0px rgba(0, 0, 0, 0.15), 
        10px 0 10px 0px rgba(0, 0, 0, 0.15);
        */
    }
    .loading-box img {
        /* border-radius: 50%;
        background-color:#fff; */
    }
</style>
