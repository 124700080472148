import Vuex from 'vuex'
import Vue from 'vue'
import cookie from 'js-cookie'

Vue.use(Vuex)
Vue.config.devtools = true
export default new Vuex.Store({
    state: {
        loadend: false,
        platform: null,
        res: {
            loading: false,
            shadow: false,
            excludeList: 'applicationdetail,createres,search'
        },
        home: {
            shadow: false
        },
        account: {
            Account: null
        },
        areaTitle: '',
        isOpen: true, 
        keywordObj: {
            keyword: ''
        },
        exp: {
            token: null,
            userid: null
        },
        studio: {
            layout:{
                mode: "",
                skin: "",
                bannerUrl: "",
                bannerColor: "",
                contentUrl: "",
                contentColor: "",
                layoutComInfo: "",
                bannerUrlDisplayMode: "",
                contentUrlDisplayMode: "",
            },
            modulelist: []
        },
        isStudio: false,
        // 记录科目信息
        subjectIdx: null
    },
    getters:{
        
    },
    mutations: {
        UPDATE_LOADEND(state) {
            state.loadend = true;
        },
        OPEN_SHADOW(state) {
            state.res.shadow = true
        },
        UPDATE_STATUS(state, boo) {
            state.isStudio = boo
        },
        OPEN_HOME_SHADOW(state){
            state.home.shadow = true
        },
        CLOSE_HOME_SHADOW(state){
            state.home.shadow = false
        },
        CLOSE_SHADOW(state) {
            state.res.shadow = false
        },
        OPEN_LOADING(state) {
            state.res.loading = true
        },
        CLOSE_LOADING(state) {
            state.res.loading = false
        },
        UPDATE_ACCOUNT(state, payload) {
            let account = payload.account.Account;
            state.account.Account = account
            var date = new Date();
            var nightDate = date.toLocaleDateString() + " " + "23:59:59";
            var span = (new Date(nightDate) - new Date()) / 1000 / 3600 / 24;
            var cookieOptions = { expires: span, path: '/' };
            var hostName = window.location.hostname;
            var domain = hostName;
            var domainArr = domain.split('.');
            var arrLength = domainArr.length;
            if (arrLength >= 2) {
                domain = domainArr[arrLength - 2] + "." + domainArr[arrLength - 1];
            }
            if (domain != 'localhost') {
                cookieOptions.domain = domain;
            }
            if (account != null) {
                //sessionStorage.setItem('account', JSON.stringify(account));
                cookie.set('account', account, cookieOptions);
            }
        },
        UPDATE_PLATFORM(state, payload) {
            state.platform = payload.platform
        },
        SELECTED_TITLE(state, title) {
            state.areaTitle = title
        },
        OEPN_TRIGGER(state, title) {
            state.isOpen = title
        },
        SEARCH_KEY(state, key) {
            state.keywordObj = key
        },
        ADD_RES_KEEPALIVE_LIST(state, arr) {
            let list = state.res.excludeList
            let _arr = list.split(',')
            _arr = _arr.concat(arr)
            list = _arr.join(',')
            state.res.excludeList = list
        },
        DELETE_RES_KEEPALIVE_LIST(state, name){
            let list = state.res.excludeList
            let _arr = list.split(',')
            let index = $.inArray(name,_arr)
            if(index >= 0){
                _arr.splice(index,1)
                list = _arr.join(',')
                state.res.excludeList = list
            }
        },
        WRITE_EXP_USERINFO(state, info){
            state.exp.token = info.token
            state.exp.userid = info.userid
        },
        UPDATE_STUDIO_LAYOUT(state, layoutinfo){
            let layout = state.studio.layout
            layout = Object.assign(layout, layoutinfo)
        },
        UPDATE_STUDIO_MODULIST(state, modulelist){
            state.studio.modulelist = modulelist
        },
        // 记录科目信息
        SET_SUBJECTIDX(state, idx) {
            state.subjectIdx = idx;
        }
    },
    actions: {

    }
})