<!--
 * @Author: your name
 * @Date: 2021-03-15 17:09:01
 * @LastEditTime: 2021-08-31 17:17:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sun_highschool\src\App.vue
-->
<template>
  <div id="app">
    <router-view v-if="flag" :Platform="Platform" :CurrentIndex="Pager.CurrentIndex" :Pager="Pager" class="main-content-out clearfix" />
    <loading></loading>
  </div>
</template>

<script>
import Loading from './components/common/site/Loading.vue'
export default {
  name: 'App',
  components: {
    Loading
  },
  data(){
    return{
      Account: this.loadAccount(),
      Platform: {},
      flag:false
    }
  },
      computed: {
        Pager: function() {
            let platformNumber = this.Platform.PlatformSystemNumber
            let currentFooter = ''
            let currentIndex = ''
            let path = this.$route.path
            let meta = this.$route.meta
            let currentSystemAlias = this.Platform.CurrentSystemAlias
            if(!this.isValid(currentSystemAlias)) {
                currentSystemAlias = 'home'
            }
            let baseAlias = null
            let alias = null
            if(this.isValid(meta)) {
                alias = meta.alias
                baseAlias = meta.baseAlias
            }
            let config = {
                HeaderFixed: false,
                HeaderName: 'app-header'
            }
            if(baseAlias == 'console') {
                config.ContainerClass = 'container100'
            }
            if(baseAlias == 'wcl' || baseAlias == 'qa') {
                config.ContainerClass = 'container100'
            }
            if (baseAlias == 'st') {
                config.HeaderFixed = true
                config.HeaderClass += ' ' + 'navbar-fixed-bg'
                config.ContainerClass = 'container100'
            }
            if(baseAlias == 'zone') {
                config.ContainerClass = 'container88'
            }
            if(baseAlias == 'zone.studio'){
                config.ContainerClass = 'container'
            }
            //金太阳资源中心
            if(platformNumber == '000012') {
                currentFooter = 'footer-sun'
                currentIndex = 'index-forclass'
                config.LogoClass = 'logo-sunclass'
                if(path == '/') {
                    if(currentSystemAlias == 'home') {
                        config.HeaderFixed = true
                        config.HeaderClass = 'forclass-header'
                        config.LogoClass += ' ' + 'logo-sun-index'
                        config.ContainerClass = 'container88'
                    } else if(currentSystemAlias == 'res') {
                        currentIndex = 'index-res-forclass'
                    } else if(currentSystemAlias == 'cm') {
                        currentIndex = 'index-cm-forclass'
                    }
                }
            }
            if(currentSystemAlias == 'v' && path == '/') {
                currentIndex = 'index-v-forclass'
            }
            return {
                Config: config,
                CurrentFooter: currentFooter,
                CurrentIndex: currentIndex
            }
        }
    },
    beforeCreate() {
        },
    created() {
        let that = this
        this.validateSession(function(){
            let session = ''
            if(sessionStorage.account){
                let account = JSON.parse(sessionStorage.account)
                session = account.session
                that.getUserPeriodSubject(function(){
                    that.flag = true
                })
                // that.getSubjectZonePeriod(session).then(res=>{
                //     that.flag = true
                // }).catch(msg=>{
                //     console.log(msg);
                //     that.$router.push({path:'/ErrorPage',query:{hidenav:'true'}})
                // })
            }
        })
        //更换新用户后清空缓存数据
        let userId = this.loadAccount().userid
        let loginUserId = localStorage.loginUserId
        let subjectId = this.getSunAccount('sc_subject')
        let loginSubjectId = localStorage.subjectId 
        if(loginUserId){
            if(loginUserId != userId || subjectId != loginSubjectId){
                let keysToRemove = ['huanJieFlag','typeFlag','versionIndex','modelIndex','loginSession','menu_opened','menu_checked','menu_idList','menu_nodeData']
                for (const key of keysToRemove) {
                    localStorage.removeItem(key)
                }
            }
        }
        localStorage.setItem('loginUserId',userId)
        localStorage.setItem('subjectId',subjectId)
    },
  mounted(){
    },
    updated(){
    }
}
</script>

<style>
body{
    background: #F2F3F6;
}
@media (min-width:768px) {
    .container {
        width: 100%
    }
}

@media (min-width:992px) {
    .container {
        width: 100%
    }
}

@media (min-width:1200px) {
    .container {
        width: 100%
    }
}
#sloading
{
    display: none;
    position:fixed;
    z-index:3001;
}
.sloading-out
{
    margin:0 auto;
    position:relative;
}
.sloading-out img
{
    width:60px;
    height:60px;
    z-index:3001;
    border-radius:50%;
    position: absolute;
    top: -5px;
    left: 0;
}
.sloading-out .poem-wrap{
    display: none;
    font-size: 20px;
}
.sloading-out .poem-character{
    display: inline-block;
    width: 25px;
    font-family: STKaiti,KaiTi,KaiTi_GB2312,"Microsoft YaHei",微软雅黑,"MicrosoftJhengHei",华文细黑,STHeiti,MingLiu;
    text-align: center;
    line-height: 50px;
    font-weight: 700;
}

.el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
    background: rgba(34, 181, 173, 0.1);
}
.el-select-dropdown__item.selected{
    color: #22B5AD;
}
.el-select .el-input.is-focus .el-input__inner{
    border-color: #22B5AD;
}

.el-input.is-active .el-input__inner, .el-input__inner:focus {
    border-color: #22B5AD;
}
</style>
