export default {
    selected: function(arr) {
        var name = ''
        if (arr) {
            arr.forEach(function(k, p) {
                if(k.Prop == 'selected') {
                    name = k.Name
                }
            })
            return name
        } else {
            return
        }
    },
    getDate: function(icon) {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        if (month < 10) {
            month = "0" + month;
        }
        if (day < 10) {
            day = "0" + day;
        }
        return [year + icon + month + icon + day, date.getTime()]
    },
    getSunSingleProp: function(atname,direction){
        if(direction && direction == 'reverse'){
            if(atname == '教案'){
                atname = '教学设计'
            }else if(atname == '学案'){
                atname = '学案'
            }else if(atname == '课件'){
                atname = '课件资源'
            }else if(atname == '素材'){
                atname = '备课素材'
            }else if(atname == '作业'){
                atname = '作业练习'
            }
        }else{
            if(atname == '教学设计'){
                atname = '教案'
            }else if(atname == '学案'){
                atname = '学案'
            }else if(atname == '课件资源'){
                atname = '课件'
            }else if(atname == '备课素材'){
                atname = '素材'
            }else if(atname == '作业练习'){
                atname = '作业'
            }
        }
        return atname;
    },
    get271SingleProp: function(atname,direction){
        if(direction && direction == 'reverse'){
            if(atname == '学习方案'){
                atname = '导学案'
            }else if(atname == '课件'){
                atname = '课件资源'
            }else if(atname == '课堂设计'){
                atname = '教学设计'
            }else if(atname == '课堂实录'){
                atname = '课堂实录'
            }else if(atname == '学习设计与指导'){
                atname = '学习设计与指导'
            }else if(atname == '单元过关'){
                atname = '单元过关'
            }else if(atname == '配套资源'){
                atname = '配套资源'
            }else if(atname == '资源包'){
                atname = '资源包'
            }else if(atname == '电子学习方案'){
                atname = '电子导学案'
            }
        }else{
            if(atname == '导学案'){
                atname = '学习方案'
            }else if(atname == '课件资源'){
                atname = '课件'
            }else if(atname == '教学设计'){
                atname = '课堂设计'
            }else if(atname == '课堂实录'){
                atname = '课堂实录'
            }else if(atname == '学习设计与指导'){
                atname = '学习设计与指导'
            }else if(atname == '单元过关'){
                atname = '单元过关'
            }else if(atname == '配套资源'){
                atname = '配套资源'
            }else if(atname == '资源包'){
                atname = '资源包'
            }else if(atname == '电子导学案'){
                atname = '学练'
            }
        }
        return atname;
    },
    initPlat: function(platform){
        let platformSystemNumber = platform.PlatformSystemNumber
        let plat = 'forclass'
        if(platformSystemNumber == '000011'){ // 271
            plat = '271'
        }else if(platformSystemNumber == '000012'){ // 000012
            plat = 'sun'
        }
        return plat
    }
}
